import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import { ErrorBoundary } from "@firecms/core"
import App from "./App"

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter basename={"/"}>
        <App/>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>
)
